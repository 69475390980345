html {
  height: 100vh;

}

.App {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* display: grid;

  grid-template-columns: 1fr 4fr;
  grid-gap: 3px;
  grid-template-rows: auto 1fr auto; */
  overflow-x: hidden;
  min-height: 100vh;

}

.footer {

  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-links {
  display: flex;
  gap: 10px;
}

.header-links>a {
  text-decoration: none;
  font-weight: bold;
  color: #7A93AC
}

.calculator {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-self: flex-start;
  align-items: flex-start;
  gap: 5px;
  grid-column: 2;
  grid-row: 2;
  text-align: left;
  margin: 20px;
  border-bottom: 1px solid #7A93AC;

}

.tools {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.tools a {
  text-decoration: none;
}

.sidebar {
  /* width: 100%; */
  /* background-color: pink; */
  min-width: 170px;
  grid-column: 1;
  grid-row: 2
}



h3.MuiTypography-root {
  font-size: 1.3rem;
  color: #7A93AC;
  font-weight: bold;
}

.electro {
  font-weight: bold;
  font-size: 1.2em;

}

.percent-comp-output {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
}

#atomic-mass {
  gap: 0px
}

.amu-error {
  color: red;
}

.header {
  grid-column: span 2;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  min-height: 100px;
  margin-bottom: 20px;
}


.featured {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  place-content: center;
  justify-content: center;
}



.logo {
  margin-top: 20px;
}

.search-bar {
  margin-top: 15px;
}

.text-field {
  width: 150px;
  min-width: fit-content;
  max-width: 30%;
}

.select-field {
  width: 200px;
}


.momentum-input>.select-field {
  color: gray;
  width: fit-content;
  font-size: 0.8rem;
}

.momentum-input,
.free-fall-input,
.planet-weight-input,
.stopping-distance-input,
.projectile-input,
.friction-input,
.hydraulic-radius-input,
.flow-input,
.wind-input,
.orifice-input,
.heat-cap-input,
.gas-input,
.resistance-input,
.cooling-input,
.conduction-input {
  display: flex;
  align-items: flex-end;
}

.diagram-image {
  width: 100px;
  height: 100px;
  padding: 10px;
}


.card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: space-between;
}

.card:hover {
  border: 1px solid #7A93AC;
  cursor: pointer;
}

.card-title {
  font-size: 0.4rem;
}


.display-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.constants,
.equations,
.conversions {
  grid-template-columns: 1fr 1fr
}


.search {
  font-size: 20px;
}


@media only screen and (max-width: 750px) {
  .App {
    max-width: 90%;
    padding: 10px;
    min-height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .header-links {
    flex-direction: column;
  }

  .featured {
    grid-template-columns: 1fr 1fr;

  }

  .constants,
  .equations,
  .conversions {
    grid-template-columns: 1fr
  }

  .calculator {
    display: block;
  }

  .calculator>h6 {
    font-size: 0.9rem;
  }

  .sidebar {
    float: right;
    line-height: 2rem;
    text-align: center;
    font-size: 1.2em;
  }

  .display-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .card-desc {
    display: none;
  }

  div.card-title {
    font-size: 0.9em;
    font-weight: bold;
  }
}


@media only screen and (max-width: 368px) {
  .App {
    max-width: 90%;
    padding: 10px;
    min-height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  ;

  .calculator {
    display: block;
  }

  .calculator>h6 {
    font-size: 0.8rem;
  }

  .sidebar {
    float: right;
    line-height: 2rem;
    text-align: center;
    font-size: 1.2em;
  }

  .display-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .card-desc {
    display: none;
  }

  div.card-title {
    font-size: 0.9em;
    font-weight: bold;
  }
}